import {
  concat,
  HttpLink,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
} from "@apollo/client";
import Cookies from "js-cookie";

const httpLink = new HttpLink({ uri: process.env.REACT_APP_ENDPOINT });

const authMiddleware = new ApolloLink((operation, forward) => {
  console.log(Cookies.get("gtt_lang"),"Cookies.get")
  const context = operation.getContext();
  console.log("📍 ~ authMiddleware ~ context:", context.headers)
 
  let lang = context.headers?.lang || Cookies.get("gtt_lang") || "en";
  Cookies.set("gtt_lang",lang);

  console.log("📍 ~ authMiddlewarexx ~ lang:", lang)
  const token = Cookies.get("gtt_access_token");
  operation.setContext({
    headers: {
      lang: lang,
      authorization: token,
    },
  });
  return forward(operation);
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
export const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache({}),
  defaultOptions: defaultOptions,
});
